<template>
  <div class="container box">
    <div class="bar">
      <b-button type="is-success" @click="isEventCreatorActive = true" id="add-event-btn">Créer un nouvel évenement</b-button>
      <b-modal
          v-model="isEventCreatorActive"
          has-modal-card
          trap-focus
          full-screen
          :destroy-on-hide="true">
        <template #default="props">
          <SzModalCreateEvent v-model="eventCreatorData" @close="props.close"></SzModalCreateEvent>
        </template>
      </b-modal>
    </div>
    <div class="eventList">
      <SzEventRow v-for="e in events" v-bind:key="e.id" :eventData="e"></SzEventRow>
    </div>
  </div>
</template>

<script>
import SzEventRow from "./SzEventRow";
import SzModalCreateEvent from "./SzModalCreateEvent";
import {mapState} from 'vuex';

export default {
  name: 'SecuritazEventManager',
  components: {SzModalCreateEvent, SzEventRow},
  data: () => ({
    isEventCreatorActive: false,
    eventCreatorData: {
      name: '',
      maxParticipants: undefined,
      rules: 'levels=BA1 sections=IN,SC',
      date: new Date()
    }
  }),
  computed: mapState({
    events: state => state.securitaz.events
  }),
  mounted() {
    this.$store.dispatch('securitaz/getEvents');
  }
}
</script>

<style scoped lang="scss">
@import '../../theme.scss';

.container {
  margin-top: 15px;

  .bar {
    display: inline;
  }

  #add-event-btn {
    margin-bottom: 10px;
  }
}

</style>
