<template>
  <div class="box search-result-box">

    <b-taglist v-if="currentPerson.section && currentPerson.level" class="event-tags is-justify-content-center">
      <b-tag :type="sectionTagType">{{ currentPerson.section }}</b-tag>
      <b-tag :type="levelTagType">{{ currentPerson.level }}</b-tag>
      <b-tag :type="registeredTagType">{{ !!registration ? 'Inscrit' : 'Non inscrit' }}
      </b-tag>
      <b-tag v-if="!!registration && registration.staff" type="is-info is-large">STAFF</b-tag>
    </b-taglist>
    <b-taglist v-else class="event-tags is-justify-content-center">
      <b-tag v-for="unit in currentPerson.units" v-bind:key="unit.name" :alt="unit.name">
        {{ unit.acronym }}
      </b-tag>
    </b-taglist>

    <div class="is-flex is-flex-direction-row is-justify-content-space-around is-align-items-center">
      <b-tag :type="nameTagType" class="result-name">
        {{ currentPerson.firstname.split(' ')[0] }} <b>{{ currentPerson.name.toUpperCase() }}</b></b-tag>
    </div>

    <hr/>

    <div class="">
      <div class="is-flex is-justify-content-space-around">
        <div class="">
          <h3>SCIPER : {{ currentPerson.sciper }}</h3>
        </div>
        <div class="">
          <div class="">
            <h3>Entrées : {{ entries }}</h3>
          </div>
        </div>
      </div>
      <div class="tile">
        <div class="tile">
          <b-button class="m-2" expanded type="is-info" tag="a" outlined
                    @click="openPeopleEPFL(currentPerson)">Page people.epfl.ch
          </b-button>
        </div>
        <div class="tile">
          <b-button v-if="entries > 0" class="m-2" expanded type="is-warning"
                    @click="newEntryForCurrent">Nouvelle entrée
          </b-button>
          <b-button v-else-if="!!registration" class="m-2" expanded type="is-success" @click="grantAccessCurrent">Faire entrer</b-button>
          <b-button v-else class="m-2" expanded type="is-danger"
                    @click="overrideCurrent">Override pour {{ currentPerson.firstname.split(' ')[0] }}
          </b-button>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'SeCurrentPerson',
  props: {
    currentPerson: {
      type: Object,
      required: true
    }
  },
  data: () => ({
  }),
  computed: {
    sectionTagType() {
      return this.$store.state.securitaz.currentEvent.parsedRules.sections.includes(this.currentPerson.section) ? 'is-success is-large' : 'is-danger is-large';
    },
    levelTagType() {
      return this.$store.state.securitaz.currentEvent.parsedRules.levels.includes(this.currentPerson.level) ? 'is-success is-large' : 'is-danger is-large';
    },
    registeredTagType() {
      return this.registration ? 'is-success is-large' : 'is-danger is-large';
    },
    nameTagType() {
      if (this.accessLog && this.accessLog.override) return 'name-tag is-medium is-warning';
      if (this.registration && this.registration.staff) return 'name-tag is-medium is-info';
      return this.registration ? 'name-tag is-medium is-success' : 'name-tag  is-medium is-danger';
    },
    registration() {
      return this.$store.state.securitaz.registrations[this.currentPerson.sciper];
    },
    accessLog() {
      return this.$store.state.securitaz.accessLogs.find(ac => ac.sciper.toString() === this.currentPerson.sciper.toString())
    },
    entries() {
      const ac = this.accessLog;
      return ac ? ac.entries : 0;
    }
  },
  methods: {
    openPeopleEPFL(p) {
      window.open('https://people.epfl.ch/' + p.profile, '_blank');
    },
    grantAccessCurrent() {
      let accessLog = this.currentPerson || {};
      accessLog.eventId = this.$store.state.securitaz.currentEventId;
      accessLog.displayName = this.currentPerson.firstname.split(' ')[0] + ' ' + this.currentPerson.name.toUpperCase();
      accessLog.device = this.$store.state.securitaz.name;

      accessLog.staff = accessLog.staff || this.registration.staff || false;
      accessLog.registered = !!this.registration || false;
      accessLog.entries = accessLog.entries + 1 || 1;
      accessLog.override = false;

      this.$store.dispatch('securitaz/createOrUpdateAccessLog', {accessLog}).then(() => {
        // this.setCurrentPerson(accessLog);
      }).catch(err => {
        console.error('Failed to grant access : ', err);
      });
    },
    overrideCurrent() {
      let accessLog = this.accessLog || this.currentPerson;
      accessLog.eventId = this.$store.state.securitaz.currentEventId;
      accessLog.displayName = this.currentPerson.firstname.split(' ')[0] + ' ' + this.currentPerson.name.toUpperCase();
      accessLog.device = this.accessLog.device || this.$store.state.securitaz.name;

      accessLog.staff = false;
      accessLog.registered = false;
      accessLog.entries = this.entries + 1;
      accessLog.override = true;

      this.$store.dispatch('securitaz/createOrUpdateAccessLog', {accessLog}).then(() => {
        // this.setCurrentPerson(accessLog);
      }).catch(err => {
        console.error('Failed to override : ', err);
      });
    },
    newEntryForCurrent() {
      let accessLog = this.accessLog;
      accessLog.entries = this.entries + 1;

      this.$store.dispatch('securitaz/createOrUpdateAccessLog', {accessLog}).then(() => {
        // this.setCurrentPerson(accessLog);
      }).catch(err => {
        console.error('Failed to add a new entry for current : ', err);
      });
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../theme.scss';

.search-result-box {
  background-color: $grey-lighter !important;
  margin-top: 15px;

  .result-name {
    font-size: xx-large;
  }
}


</style>
