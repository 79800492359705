import { render, staticRenderFns } from "./SecuritazRegistration.vue?vue&type=template&id=5c34abd7&scoped=true&"
import script from "./SecuritazRegistration.vue?vue&type=script&lang=js&"
export * from "./SecuritazRegistration.vue?vue&type=script&lang=js&"
import style0 from "./SecuritazRegistration.vue?vue&type=style&index=0&id=5c34abd7&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c34abd7",
  null
  
)

export default component.exports