<template>
  <div class="sl">
    <b-field :label="description + (required ? ' (Required)' : '')">
      <b-input  @input="handleInput" type="input"></b-input>
    </b-field>
  </div>
</template>

<script>
export default {
  name: 'SingleLine',
  props: {
    description: String,
    required: Boolean
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value);
    }
  }
}
</script>

<style>

.sl {
  text-align: left;
  margin-bottom: 15px;
}

</style>