<template>
  <div class="container box overflow-auto">
    <div class="columns overflow-auto">
      <div class="column reg-form">
        <div class="is-flex">
          <b-button type="is-info" @click="$router.push({name:'securitaz-mainView', params: $route.params})" style="margin-right: 10px;">Vue principale</b-button>
          <b-autocomplete
              class="is-flex-grow-1"
              placeholder="Recherche via nom, prénom ou SCIPER"
              keep-first
              :data="searchResults"
              @typing="searchPeople"
              expanded
              :custom-formatter="p => `${p.firstname} ${p.name.toUpperCase()} [${p.sciper}]`"
              @select="selected => display(selected)"
              clearable/>
        </div>
        <div v-if="displayResult" class="box search-result-box">

          <b-taglist v-if="currentPerson.section && currentPerson.level"
                     class="event-tags is-justify-content-center" attached>
            <b-tag type="is-info is-large">{{ currentPerson.section }}</b-tag>
            <b-tag type="is-dark is-large">{{ currentPerson.level }}</b-tag>
          </b-taglist>
          <b-taglist v-else class="event-tags is-justify-content-center">
            <b-tag v-for="unit in currentPerson.units" v-bind:key="unit.name" :alt="unit.name">
              {{ unit.acronym }}
            </b-tag>
          </b-taglist>

          <div class="is-flex is-flex-direction-row is-justify-content-space-around is-align-items-center">
            <h1 class="result-name">{{ currentPerson.firstname }} <b>{{ currentPerson.name }}</b></h1>
          </div>

          <hr/>

          <div class="">
            <div class="is-flex is-justify-content-space-around">
              <div class="">
                <h3>SCIPER: {{ currentPerson.sciper }}</h3>
              </div>
              <div class="">
                <b-field>
                  <b-checkbox size="is-medium" :disabled="currentPersonLoading" v-model="currentPersonIsStaff">Membre du
                    staff
                  </b-checkbox>
                </b-field>
              </div>
            </div>
            <div class="tile">
              <div class="tile">
                <b-button class="m-2" expanded type="is-info" tag="a" outlined
                          @click="openPeopleEPFL(currentPerson)">Page people.epfl.ch
                </b-button>
              </div>
              <div class="tile">
                <b-button v-if="currentPersonIsRegistered" class="m-2" expanded type="is-danger"
                          :loading="currentPersonLoading" @click="deleteRegistration">Désinscrire
                  {{ currentPerson.firstname }}
                </b-button>
                <b-button v-else class="m-2" expanded type="is-success" :loading="currentPersonLoading"
                          @click="createRegistration">Inscrire {{ currentPerson.firstname }}
                </b-button>
              </div>
            </div>
          </div>


        </div>
      </div>

      <div class="column overflow-auto">
        <b-button class="" outlined expanded type="is-danger" @click="importFromList">Importer depuis une liste
        </b-button>

        <b-modal v-model="isUploadListModalActive">
          <section class="box">
            <b-field class="file">
              <b-upload v-model="file" expanded>
                <a class="button is-primary is-fullwidth">
                  <b-icon icon="upload"></b-icon>
                  <span>{{ file.name || "Cliquer pour choisir un fichier" }}</span>
                </a>
              </b-upload>
            </b-field>
            <b-field>
              <b-upload v-model="file" drag-drop expanded>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-large"></b-icon>
                    </p>
                    <p>Glissez votre fichier ici ou cliquez</p>
                  </div>
                </section>
              </b-upload>
            </b-field>
            <div class="is-flex is-justify-content-space-around">
              <b-field>
                <b-select v-model="fileMode" placeholder="Type de fichier">
                  <option>1. Un mail par ligne</option>
                  <option>2. Un SCIPER par ligne</option>
                </b-select>
              </b-field>
              <b-button :disabled="file.length===0" :loading="importListLoading" type="is-danger" outlined
                        @click="importList">Importer la liste
              </b-button>
            </div>
          </section>
        </b-modal>

        <div class="box registrations overflow-auto">
          <div class="box" v-for="registration in registrations" v-bind:key="registration.sciper">
            <h1>{{ registration.firstname }} {{ registration.name }}</h1>
            <b-button class="" outlined type="is-danger" @click="deleteInline(registration.sciper)">Retirer</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';

export default {
  name: 'SecuritazRegistration',
  components: {},
  data: () => ({
    searchResults: [],
    currentPerson: {},
    displayResult: false,
    currentPersonLoading: true,
    currentPersonIsRegistered: false,
    currentPersonIsStaff: false,
    isUploadListModalActive: false,
    file: [],
    fileMode: '',
    importListLoading: false
  }),
  computed: mapState({
    registrations: state => state.securitaz.registrations
  }),
  methods: {
    ...mapActions({}),
    searchPeople(value) {
      this.displayResult = false;
      // if (value.length < 3) return;
      this.$store.dispatch('securitaz/searchPeople', {search: value}).then(results => {
        this.searchResults = results;
      });
    },
    display(p) {
      this.currentPerson = p;
      this.displayResult = true;
      let names = [];
      for (let i = 0; i < this.currentPerson.units.length; i++) {
        const unit = this.currentPerson.units[i];
        if (names.includes(unit.name)) {
          this.currentPerson.units.splice(i, 1);
        } else {
          names.push(unit.name);
        }
      }

      const registration = this.$store.state.securitaz.registrations[p.sciper];
      this.currentPersonIsRegistered = !!registration;
      this.currentPersonIsStaff = this.currentPersonIsRegistered ? registration.staff : false;
      this.currentPersonLoading = false;

    },
    createRegistration() {
      this.currentPersonLoading = true;
      let registration = this.currentPerson;
      registration.staff = this.currentPersonIsStaff;
      registration.eventId = this.$route.params.eventId;
      registration.addedLater = false;
      this.$store.dispatch('securitaz/createRegistration', {registration}).then(() => {
        this.currentPersonLoading = false;
        this.currentPersonIsRegistered = true;
      });
    },
    deleteInline(sciper) {
      this.$store.dispatch('securitaz/deleteRegistration', {
        eventId: this.$route.params.eventId,
        sciper
      }).then(() => {
        console.log('Removed '+sciper);
      }).catch(err => {
        console.log('Failed to remove '+sciper, err);
      });
    },
    deleteRegistration() {
      this.currentPersonLoading = true;
      this.$store.dispatch('securitaz/deleteRegistration', {
        eventId: this.$route.params.eventId,
        sciper: this.currentPerson.sciper
      }).then(() => {
        this.currentPersonLoading = false;
        this.currentPersonIsRegistered = false;
        this.currentPersonIsStaff = false;
      }).catch(err => {
        this.currentPersonLoading = false;
        console.error('Failed to delete registration', err);
      });
    },
    openPeopleEPFL(p) {
      window.open('https://people.epfl.ch/' + p.profile, '_blank');
    },
    importFromList() {
      this.isUploadListModalActive = true;
    },
    importList() {
      this.importListLoading = true;
      this.file.text().then(text => {
        let values;
        switch (this.fileMode.charAt(0)) {
          case '1':
          case '2':
            values = text.split('\n');
            for (let i = 0; i < values.length; i++) {
              if (values[i].length === 0
                  || (this.fileMode.charAt(0) === '1' && values[i].indexOf('@epfl.ch') === -1)
                  || (this.fileMode.charAt(0) === '2' && isNaN(parseInt(values[i])))) {
                values.splice(i, 1);
                console.log('Removed index:' + i);
              }
            }
            console.log(values.length, values);

            break;

          default:
            this.importListLoading = false;
        }

        const count_before = values.length;
        values = [...new Set(values)] // deduplication
        if (count_before !== values.length) {
          console.log('Removed ' + count_before - values.length + ' duplicates');
        }

        let payload = {};
        switch (this.fileMode.charAt(0)) {
          case '1':
            payload = {emails: values}
            break;
          case '2':
            payload = {scipers: values};
            break;
          default:
            console.error('unimplemented file mode');
        }
        payload.eventId = this.$route.params.eventId;
        payload.staff = false;
        payload.addedLater = false;
        this.$store.dispatch('securitaz/importRegistrations', payload).then(() => {
          this.importListLoading = false;
          this.isUploadListModalActive = false;
        }).catch(err => {
          this.importListLoading = false;
          console.error('failed to import the list', err);
        })
      })
    }
  },
  mounted() {
    this.$store.dispatch('securitaz/getRegistrations', {eventId: this.$route.params.eventId});
  }
}
</script>

<style scoped lang="scss">
@import '../../theme.scss';

.container {
  margin-top: 15px;
  max-height: 95%;

  .search-result-box {
    background-color: $grey-lighter !important;
    margin-top: 15px;

    .result-name {
      font-size: xx-large;
    }
  }

  .registrations {
    margin-top: 15px;
    background-color: $grey-lighter !important;
    height: 80vh;
  }

  .overflow-auto {
    overflow: auto !important;
  }

}

</style>
