<template>
  <div class="container">
    <form action="">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">New event</p>
          <button type="button" class="delete" @click="$emit('close')"/>
        </header>
        <section class="modal-card-body modal-event-content">

          <b-field id="datetime" label="Date et heure">
            <b-datetimepicker
                v-model="eventCreatorData.date"
                placeholder="Click to select..."
                icon="calendar-today"
                locale="locale"
                inline
                :datepicker="{showWeekNumber: true}"
                :timepicker="{ hourFormat: '24' }">
            </b-datetimepicker>
          </b-field>

          <div id="rightCol">
            <b-field label="Nom de l'événement">
              <b-input type="text" v-model="eventCreatorData.name" placeholder="Soirée Cinéma" required/>
            </b-field>

            <b-field label="Règles d'ajout">
              <b-input type="text" v-model="eventCreatorData.rules" placeholder="levels=BA1 sections=IN,SC"/>
            </b-field>

            <b-field label="Nombre max de participants">
              <b-numberinput v-model="eventCreatorData.maxParticipants" placeholder="Rien = pas de limite"/>
            </b-field>
          </div>


        </section>
        <footer class="modal-card-foot">
          <b-button label="Close" @click="$emit('close')"/>
          <b-button label="Create event" type="is-success" :loading="loading" @click="sendForm"/>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'SzModalCreateEvent',
  data: () => ({
    loading: false
  }),
  model: {
    prop: 'eventCreatorData',
    event: 'change'
  },
  props: {
    eventCreatorData: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    sendForm() {
      this.loading = true;
      const date = this.eventCreatorData.date.toISOString();
      const eventData = {
        name: this.eventCreatorData.name,
        date,
        rules: this.eventCreatorData.rules,
        maxParticipants: this.eventCreatorData.maxParticipants || -1
      }
      this.$store.dispatch('securitaz/createEvent', {eventData}).then(() => {
        this.$parent.close();
      }).catch(message => {
        console.error('Failed to create event : ', message);
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../theme.scss';

.modal-event-content {
  display: flex;
  justify-content: space-around;
  padding: 10px;

  #datetime {

  }

  #rightCol {

  }

}

</style>
