<template>
  <div class="container">
    <img alt="logo" id="logo" src="../assets/logo.png">
    <b-loading is-full-page v-model="pageLoading"></b-loading>

    <div v-if="showForm" id="form" class="box">
      <h1 class="title is-primary">{{ title }}</h1>
      <h3 class="subtitle is-primary">{{ description }}</h3>
      <form v-on:submit.prevent>
        <component v-for="field in fields" v-bind:is="field.type" :key="field.name" :description="field.description"
                    :required="field.required" @input="updateFieldValue(field.name, $event)"></component>
        <SignatureBox></SignatureBox>
        <hr>
        <b-button type="is-success" :loading="awaitingServer" :disabled="preventSending" @click="sendForm">Sign !</b-button>
      </form>
    </div>

    <div v-if="message !== ''" id="messageBox" class="box">
      <b-notification type="is-success">
        {{ message }}
      </b-notification>

      <b-button v-id="pdfLink !== ''" type="is-success" tag="a" :href="pdfLink">Show my PDF !</b-button>
    </div>

    <div v-if="error !== ''" id="errorBox" class="box">
      <b-notification type="is-critical">
        {{ error }}
      </b-notification>
    </div>

  </div>
</template>

<script>
//import Vue from 'vue';
import {mapState, mapActions} from 'vuex';
import SignatureBox from "@/components/SignatureBox";
import SingleLine from "@/components/fields/SingleLine";
import MultiLine from "@/components/fields/MultiLine";

function getMissingField(fields) {
  let missingFields = [];
  for (let field of fields) {
    if (field.required && field.value === '') {
      missingFields.push(field);
    }
  }
  return missingFields;
}

export default {
  name: 'EzSign',
  components: {
    SignatureBox,
    SingleLine,
    MultiLine
  },
  props: {
    cookie: String,
    formName: String
  },
  data: function () {
    return {
      count: 0,

    }
  },
  computed: {
    ...mapState({
      title: state => state.ezsign.title,
      description: state => state.ezsign.description,
      awaitingServer: state => state.ezsign.awaitingServer,
      fields: state => state.ezsign.fields,
      pageLoading: state => state.ezsign.awaitingServer && state.ezsign.signatureBase64 === '',
      showForm: state => state.ezsign.error === '' && state.ezsign.message === '',
      message: state => state.ezsign.message,
      error: state => state.ezsign.error,
      preventSending: state => state.ezsign.signatureBase64 === '',
      pdfLink: state => state.ezsign.pdfLink
    }),
  },
  methods: {
    ...mapActions([
    ]),
    updateFieldValue(fieldName, value) {
      this.$store.commit('updateFieldValue', {fieldName, value});
    },
    sendForm() {
      const missingFields = getMissingField(this.$store.state.ezsign.fields);
      if (missingFields.length > 0) {
        this.$buefy.dialog.alert({
          title: 'Missing required fields',
          message: 'You need to fill every field marked as "Required"',
          type: 'is-danger',
          hadIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
        })
        return;
      }

      this.$store.dispatch('sendForm', {formName: this.$props.formName, cookie: this.$props.cookie});
    }
  },
  mounted() {
    this.$store.dispatch('getForm', {formName: this.$props.formName, cookie: this.$props.cookie});
  }
}
</script>

<style scoped lang="scss">
@import '../theme.scss';

.container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 400px;
}

#logo {
  height: 96px;
  margin: 10px;
}

#form {

  form {

    .formLabel {
      text-align: left;
    }
  }
}

</style>
