<template>
  <div class="box">
    <div class="">
      <h2>Nombre de personnes rentrées : {{ numberOfEntries }}</h2>
      <h2>Nombre de personnes inscrites : {{ numberOfRegisteredPeople }}</h2>
      <h2>Parmis les personnes rentrées <br/> {{ numberOfUnregisteredEntries }} non-inscrites - {{ numberOfRegisteredEntries }} inscrites </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecuritazStats',
  data: () => ({
  }),
  computed: {
    numberOfEntries() {
      return this.$store.state.securitaz.accessLogs.filter(ac => ac.entries > 0).length;
    },
    numberOfRegisteredPeople() {
      return Object.keys(this.$store.state.securitaz.registrations).length;
    },
    numberOfRegisteredEntries() {
      return this.$store.state.securitaz.accessLogs.filter(ac => ac.entries > 0 && ac.registered).length;
    },
    numberOfUnregisteredEntries() {
      return this.$store.state.securitaz.accessLogs.filter(ac => ac.entries > 0 && !ac.registered).length;
    }
  },
  mounted() {
    this.$store.dispatch('securitaz/getRegistrations', {eventId: this.$route.params.eventId});
    this.$store.dispatch('securitaz/getAccessLogs', {eventId: this.$route.params.eventId});
  }
}
</script>

<style scoped lang="scss">
@import '../../theme.scss';

div.tagslist {
  margin-bottom: 3px !important;
}

.name-tag {
  width: 100%;
}


</style>
