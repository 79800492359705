<template>
  <div>
    <h1>Welcome</h1>
    <p>
      Nothing here. There is no information here, this website is only used for specific web services.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Error404'
}
</script>

<style scoped>

</style>
