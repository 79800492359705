<template>
  <div class="ml">
    <b-field :label="description">
      <b-input type="textarea"></b-input>
    </b-field>
  </div>
</template>

<script>
export default {
  name: 'MultiLine',
  props: {
    description: String
  }
}
</script>

<style>

.ml {
  text-align: left;
  margin-bottom: 15px;
}

</style>