import { render, staticRenderFns } from "./SecuritazLogin.vue?vue&type=template&id=b15c0d90&scoped=true&"
import script from "./SecuritazLogin.vue?vue&type=script&lang=js&"
export * from "./SecuritazLogin.vue?vue&type=script&lang=js&"
import style0 from "./SecuritazLogin.vue?vue&type=style&index=0&id=b15c0d90&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b15c0d90",
  null
  
)

export default component.exports