<template>
  <div>
    <h1>Page not found</h1>
    <p>
      Nothing here. It may be either an old link or a URL typo. If you think this is an error, please contact the committee.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Error404'
}
</script>

<style scoped>

</style>
