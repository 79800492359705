<template>
  <div class="container">
    <h1 id="title">Connexion - Securitaz</h1>
    <form class="box" v-on:submit.prevent>
      <b-field label="Nom d'instance">
        <b-input type="text" placeholder="Laptop Entrée" size="is-medium" v-model="name" required></b-input>
      </b-field>
      <b-field label="Code d'accès" :type="fieldMessage !== '' ? 'is-danger' : ''" :message="fieldMessage">
        <b-input type="password" placeholder="*******" password-reveal size="is-medium" v-model="accessCode" required></b-input>
      </b-field>
      <b-button type="is-primary" tag="input" native-type="submit" :disabled="loading" @click="login" value="Connexion">Connexion</b-button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'SecuritazLogin',
  data: () => ({
    loading: false,
    fieldMessage: '',
    accessCode: localStorage.getItem('accessCode') || '',
    name: localStorage.getItem('name') || ''
  }),
  methods: {
    login() {
      if (this.accessCode === '' || this.name === '') return;
      this.loading = true;
      this.$store.dispatch('securitaz/login', {name: this.name, accessCode: this.accessCode}).then(() => {
        localStorage.setItem('accessCode', this.accessCode);
        localStorage.setItem('name', this.name);
        this.$router.push({name: 'securitaz-eventManager'});
      }).catch(err => {
        console.error('Failed login : ', err);
        this.fieldMessage = 'Authentication failed';
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../theme.scss';
#title {
  font-size: 20pt;
  padding: 30px;
  color: $light;
}
</style>
