<template>
  <div class="box">
    <div class="columns">
      <div class="column is-8">
        <div class="is-flex">
          <b-taglist class="tagslist is-align-content-flex-start is-flex-grow-1">
            <b-tag v-if="this.accessLog.section!==''" :type="sectionTagType" class="top-tags">{{ this.accessLog.section }}</b-tag>
            <b-tag v-if="this.accessLog.section!==''" :type="levelTagType" class="top-tag">{{ this.accessLog.level }}</b-tag>
            <b-tag v-else class="top-tag">Non-étudiant</b-tag>
            <b-tag :type="registeredTagType" class="top-tags">{{ this.accessLog.registered ? 'Inscrit' : 'Non inscrit' }}</b-tag>
          </b-taglist>
          <b-taglist class="top-tags">
            <b-tag v-if="accessLog.staff" type="is-info">STAFF</b-tag>
            <b-tag v-if="accessLog.entries > 0" type="is-info">{{ accessLog.entries }}</b-tag>
          </b-taglist>
        </div>
        <b-button type="is-info" :class="nameTagType" @click="onClickSelected">{{ this.accessLog.displayName }}</b-button>
        <span style="font-size: 9pt;">@{{ accessLog.device }}</span>
      </div>
      <div class="column is-flex is-flex-direction-column">
        <b-button v-if="accessLog.entries > 1" class="m-1" expanded type="is-danger" @click="removeOneEntry">Décompter</b-button>
        <b-button v-if="accessLog.entries === 1" class="m-1" expanded type="is-danger" @click="removeOneEntry">Annuler</b-button>
        <b-button class="m-1" expanded type="is-black" outlined @click="deleteEntry">Supprimer</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SzAccessLogRow',
  props: {
    accessLog: {
      type: Object,
      required: true
    }
  },
  data: () => ({
  }),
  computed: {
    sectionTagType() {
      return this.$store.state.securitaz.currentEvent.parsedRules.sections.includes(this.accessLog.section) ? 'is-success' : 'is-danger';
    },
    levelTagType() {
      return this.$store.state.securitaz.currentEvent.parsedRules.levels.includes(this.accessLog.level) ? 'is-success' : 'is-danger';
    },
    registeredTagType() {
      return this.accessLog.registered ? 'is-success' : 'is-danger';
    },
    nameTagType() {
      if (this.accessLog.override) return 'name-tag is-medium is-warning';
      if (this.accessLog.staff) return 'name-tag is-medium is-info';
      return this.accessLog.registered ? 'name-tag is-medium is-success' : 'name-tag  is-medium is-danger';
    },
  },
  methods: {
    onClickSelected() {
      this.$emit('selected', this.accessLog.sciper);
    },
    removeOneEntry() {
      this.accessLog.entries = this.accessLog.entries - 1;
      if (this.accessLog.entries === 0)
        this.accessLog.override = false;

      this.$store.dispatch('securitaz/createOrUpdateAccessLog', {accessLog: this.accessLog}).then(() => {

      }).catch(err => {
        console.error('Failed to update this.accessLog ',this.accessLog, err);
      });
    },
    deleteEntry() {
      const t = this;
      this.$buefy.dialog.confirm({
        title: 'Suppression',
        message: `Voulez-vous vraiment supprimer l'access log concernant ${this.accessLog.displayName} ? Ne le faites que pour résoudre un problème d'affichage ou parce que vous savez ce que vous faites. Si vous voulez juste annuler une entrée c'est l'autre bouton qu'il faut utiliser.`,
        confirmText: 'Je sais ce que je fais, je supprime',
        cancelText: 'Oups, j\'annule',
        onConfirm: () => {
          t.$store.dispatch('securitaz/deleteAccessLog', {sciper: t.accessLog.sciper, eventId: t.accessLog.eventId});
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../theme.scss';

div.tagslist {
  margin-bottom: 3px !important;
}

.name-tag {
  width: 100%;
}


</style>
