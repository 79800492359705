<template>
  <b-field label="Signature">
    <div id="box">
      <div id="canvasBox">
        <canvas class="js-sig-canvas sig" width="300" height="125"></canvas>
      </div>
      <b-button class="is-primary js-sig-reset sig-reset" :disabled="!resetEnabled" @click="reset">Reset signature</b-button>
    </div>
  </b-field>
</template>

<script>
import SignaturePad from 'signature_pad';
import { mapState } from 'vuex';

let canvas;

export default {
  name: 'SignatureBox',
  props: {},
  data: function () {
    return {

    }
  },
  computed: mapState({
    resetEnabled: state => state.ezsign.signatureBase64 !== ''
  }),
  mounted: function() {
    canvas = document.querySelector(".js-sig-canvas");
    const t = this;
    const signaturePad = new SignaturePad(canvas, {
      minWidth: 1,
      maxWidth: 1,
      onBegin: function () {
        console.log('Begin')
      },
      onEnd: function () {
        console.log('End');
        t.$store.commit('updateSignature', signaturePad.toDataURL());
      }
    });
  },
  methods: {
    startLoading() {
      this.loading = true;
    },
    reset() {
      this.$store.commit('updateSignature', '');
      let canvasContext = document.querySelector('.js-sig-canvas').getContext('2d');
      canvasContext.clearRect(0,0, canvas.width, canvas.height);
    }
  }
}
</script>

<style scoped lang="scss">
#box {

  display: flex;
  flex-direction: column;
  align-items: center;

  #canvasBox {
    display: flex;
    justify-content: center;
    .js-sig-canvas {
      background-color: white;
      border-radius: 4px;
      border: 1px solid #dbdbdb;;
    }
  }

  button {
    margin-top: 5px;
    width: 300px;
  }

}

</style>
