import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import './theme.scss'
import VueRouter from 'vue-router'
import 'es6-promise/auto'
import Vuex from 'vuex'
import Axios from 'axios';
import {DialogProgrammatic as Dialog} from 'buefy';
import {ToastProgrammatic as Toast} from 'buefy';

const uuid = require('uuid').v4;

import EzSign from './components/EzSign'
import Error404 from "./components/Error404";
import Welcome from "./components/Welcome";
import SecuritazEventManager from "./components/securitaz/SecuritazEventManager";
import SecuritazLogin from "./components/securitaz/SecuritazLogin";
import SecuritazMainView from "./components/securitaz/SecuritazMainView";
import SecuritazRegistration from "./components/securitaz/SecuritazRegistration";
import SecuritazStats from "./components/securitaz/SecuritazStats";
//import {Promise} from "es6-promise";

Vue.use(Vuex)
Vue.use(VueRouter);
Vue.use(Buefy);

Vue.config.productionTip = false

let store = new Vuex.Store({

    modules: {
        ezsign: {
            state: () => ({
                title: 'Titre du form',
                description: '',
                fields: [],
                signatureBase64: '',
                awaitingServer: false,
                error: '',
                message: '',
                pdfLink: ''
            }),
            mutations: {
                updateSignature(state, signature) {
                    state.signatureBase64 = signature;
                },
                updateFieldValue(state, {fieldName, value}) {
                    for (let field of state.fields) {
                        if (field.name === fieldName) {
                            field.value = value;
                            break;
                        }
                    }
                },
                waitForServer(state) {
                    state.awaitingServer = true;
                },
                error(state, errorMessage) {
                    state.error = errorMessage;
                    state.awaitingServer = false;
                },
                formReady(state, serverResponse) {
                    state.title = serverResponse.clientInfo.title;
                    state.description = serverResponse.clientInfo.description;
                    state.fields = serverResponse.fields;
                    for (let field of state.fields) {
                        field.value = '';
                    }
                    state.awaitingServer = false;
                },
                message(state, {message, link}) {
                    state.message = message;
                    state.awaitingServer = false;
                    state.pdfLink = link;
                }
            },
            actions: {

                getForm({commit}, {formName, cookie}) {
                    commit('waitForServer');
                    Axios.get(`/api/ezsign/${formName}/${cookie}`).then(response => {
                        if (response.data.message && response.data.message === 'already generated') {
                            commit('message', {message: 'Vous avez déjà signé ce document', link: response.data.link});
                        } else {
                            commit('formReady', response.data);
                        }
                    }).catch((error) => {
                        if (error.toString().indexOf('404') !== -1) {
                            commit('error', 'Dude wtf you\'re messing with my urls. Not cool :(');
                        } else {
                            commit('error', 'Failed to reach the server or unknown error, you might want to contact the Coaching IC Committee :/');
                        }
                        console.error(error);
                    });
                },

                sendForm({commit, state}, {formName, cookie}) {
                    commit('waitForServer');
                    Axios.post(`/api/ezsign/${formName}/${cookie}`, {
                        fields: state.fields,
                        signature: state.signatureBase64
                    }).then(function (response) {
                        commit('message', {
                            message: response.data.message || 'A strange thing happend but it should be okay ... :/',
                            link: response.data.link
                        });
                    }).catch(function (error) {
                        commit('error', 'An error occurred, you might want to contact the Coaching IC Committee :(');
                        console.error(error);
                    });
                }
            }
        },

        securitaz: {
            namespaced: true,
            state: () => ({
                loggedIn: false,
                name: '',
                accessCode: '',
                events: [],
                currentEventId: '',
                currentEvent: {},
                registrations: {},
                accessLogs: [],
                devices: [],
            }),
            mutations: {
                loggedIn(state, {name, accessCode}) {
                    state.loggedIn = true;
                    state.name = name;
                    state.accessCode = accessCode;
                    reconnectDialogEnabled = true;
                },

                addEvent(state, {event}) {
                    state.events.push(event);
                },
                setEvents(state, {events}) {
                    state.events = events;
                },

                setCurrentEventId(state, {eventId}) {
                    state.currentEventId = eventId;
                    for (let event of state.events) {
                        if (event.id === eventId) {
                            state.currentEvent = event;
                            break;
                        }
                    }
                },

                addRegistration(state, {registration}) {
                    Vue.set(state.registrations, registration.sciper, registration);
                },
                setRegistrations(state, {registrations}) {
                    state.registrations = registrations;
                },
                removeRegistration(state, {sciper}) {
                    Vue.delete(state.registrations, sciper);
                },

                createOrUpdateAccessLog(state, {accessLog, accessGranted}) {
                    if (!state.devices.includes(accessLog.device)) state.devices.push(accessLog.device);
                    let isNew = true;
                    for (let ac of state.accessLogs) {
                        if (ac.sciper.toString() === accessLog.sciper.toString() && ac.eventId === accessLog.eventId) {
                            for (let k in accessLog) {
                                ac[k] = accessLog[k];
                            }
                            isNew = false;
                        }
                    }
                    if (isNew) {
                        accessLog['entries'] = accessLog['entries'] || (accessGranted ? 1 : 0);
                        state.accessLogs.unshift(accessLog);
                    } else {
                        state.accessLogs.sort((a, b) => new Date(b.time) - new Date(a.time)); // flipped: ok
                    }
                },
                setAccessLogs(state, {accessLogs}) {
                    state.accessLogs = accessLogs;
                    for (let a of accessLogs) {
                        if (!state.devices.includes(a.device)) state.devices.push(a.device);
                    }
                },
                removeAccessLog(state, {eventId, sciper}) {
                    for (let i = 0; i < state.accessLogs.length; i++) {
                        const ac = state.accessLogs[i];
                        if (ac.eventId === eventId && ac.sciper === sciper) {
                            state.accessLogs.splice(i, 1);
                            break;
                        }
                    }
                }
            },
            actions: {

                reconnectWS({state}) {
                    connectWS(state.name, state.accessCode);
                },

                login({commit}, {accessCode, name}) {
                    return new Promise((resolve, reject) => {
                        wsSend({request: 'securitaz-login', accessCode, name}, data => {
                            if (!data.success) reject(data);
                            else {
                                commit('loggedIn', {name, accessCode});
                                resolve();
                            }
                        });
                    });
                },

                createEvent(_, {eventData}) {
                    return new Promise((resolve, reject) => {
                        eventData.request = 'securitaz-create-event';
                        wsSend(eventData, data => {
                            if (!data.success) reject(data);
                            else {
                                // Data mutation handled at global dispatch
                                resolve();
                            }
                        });
                    });
                },

                getEvents({commit}) {
                    wsSend({request: 'securitaz-get-events'}, data => {
                        commit('setEvents', data);
                    });
                },

                searchPeople(_, {search}) {
                    return new Promise(resolve => {
                        wsSend({request: 'securitaz-search-people', search}, data => {
                            resolve(data.results);
                        });
                    });
                },

                getRegistrations({commit}, {eventId}) {
                    commit('setCurrentEventId', {eventId});
                    wsSend({request: 'securitaz-get-registrations', eventId}, data => {
                        const registrations = {};
                        for (let r of data.registrations) {
                            registrations[r.sciper] = r;
                        }
                        commit('setRegistrations', {registrations});
                    });
                },

                createRegistration(_, {registration}) {
                    return new Promise((resolve, reject) => {
                        registration.request = 'securitaz-create-registration';
                        wsSend(registration, data => {
                            if (!data.success) reject(data);
                            else {
                                // Data mutation handled at global dispatch
                                resolve();
                            }
                        });
                    });
                },

                importRegistrations(_, objects) {
                    return new Promise((resolve, reject) => {
                        objects.request = 'securitaz-batch-create-registrations';
                        wsSend(objects, data => {
                            if (!data.success) reject(data);
                            else {
                                resolve();
                            }
                        });
                    });
                },

                deleteRegistration(_, {eventId, sciper}) {
                    return new Promise((resolve, reject) => {
                        wsSend({request: 'securitaz-delete-registration', eventId, sciper}, data => {
                            if (!data.success) reject(data);
                            else {
                                resolve();
                            }
                        });
                    });
                },

                createOrUpdateAccessLog(_, {accessLog}) {
                    return new Promise((resolve, reject) => {
                        accessLog.request = 'securitaz-create-update-access-log';
                        wsSend(accessLog, data => {
                            if (!data.success) reject(data);
                            else {
                                // Data mutation handled at global dispatch
                                resolve();
                            }
                        });
                    });
                },

                getAccessLogs({commit}, {eventId}) {
                    wsSend({request: 'securitaz-get-access-logs', eventId}, data => {
                        commit('setAccessLogs', {accessLogs: data.accessLogs});
                    });
                },

                deleteAccessLog(_, {eventId, sciper}) {
                    wsSend({request: 'securitaz-delete-access-log', eventId, sciper}, data => {
                        if (!data.success) {
                            Toast.open({type: 'is-danger', message: 'Echec de la suppression, voir la console'});
                            console.error('Deletion failed :', data);
                        }
                    });
                },

            }
        }
    }
});

const router = new VueRouter({
    routes: [
        {path: '/ezsign/:formName/:cookie', component: EzSign, props: true},

        {
            path: '/securitaz',
            name: 'securitaz',
            beforeEnter: (_, ignore, next) => {
                if (store.state.securitaz.loggedIn) next({name: 'securitaz-eventManager'});
                else {
                    next({name: 'securitaz-login'});
                }
            }
        },
        {
            path: '/securitaz/login',
            name: 'securitaz-login',
            component: SecuritazLogin
        },
        {
            path: '/securitaz/events',
            name: 'securitaz-eventManager',
            meta: {securitazAuth: true},
            component: SecuritazEventManager
        },
        {
            path: '/securitaz/events/:eventId',
            name: 'securitaz-mainView',
            meta: {securitazAuth: true},
            component: SecuritazMainView
        },
        {
            path: '/securitaz/events/:eventId/registration',
            name: 'securitaz-registration',
            meta: {securitazAuth: true},
            component: SecuritazRegistration
        },
        {
            path: '/securitaz/events/:eventId/stats',
            name: 'securitaz-stats',
            meta: {securitazAuth: true},
            component: SecuritazStats
        },
        {path: '/securitaz/*', redirect: {name: 'securitaz'}},

        {path: '/', component: Welcome},
        {path: '*', component: Error404}
    ],
    mode: 'history'
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.securitazAuth)) {
        // this Securitaz route requires to be logged on
        if (store.state.securitaz.loggedIn !== true && to.name !== 'securitaz-login') {
            next({name: 'securitaz-login'});
        } else next();
    } else next();
});

const loc = window.location;
/***
 * Okay this is annoying : Netlify can't proxy wss so let's just use my server directly and fuck Netlify
 */
const wsURI = loc.protocol === 'https:' ? 'wss://coaching-api.loggerz.net/api' : 'ws://localhost:3000/api'; // https: prod, http:dev ... cringe I know
let webSocket = new WebSocket(wsURI);
let wsHandlers = {}; // store the temporary callbacks of wsSend()

function wsSend(data, callback) {
    if (callback) {
        const id = uuid();
        data['id'] = id;
        wsHandlers[id] = callback;
    }
    webSocket.send(JSON.stringify(data));
}

let reconnectDialogEnabled = false;

function showReconnectDialog() {
    if (!reconnectDialogEnabled) return;
    Dialog.confirm({
        title: 'Kluterite Aigue',
        message: 'EUh ... la co est pas ouf ou c\'est moi ? Bref la connexion WebSocket avec le serveur a été interrompue. Tu peux la rétablir en cliquant sur reconnexion.',
        confirmText: 'Reconnexion',
        cancelText: 'Nope, j\'en ai marre',
        type: "is-danger",
        onConfirm: () => store.dispatch('securitaz/reconnectWS')
    });
}

function connectWS(name, accessCode) {
    webSocket = new WebSocket(wsURI);
    webSocket.addEventListener('open', () => {
        console.log(name ? 'Reconnected to WebSocket Server, logging in ...' : 'Connected to WebSocket Server');
        if (name && accessCode) {
            store.dispatch('securitaz/login', {name, accessCode}).then(() => {
                console.log('Back to normal :)');
                Toast.open({type: 'is-success', message: 'C\'est bon, tout est réglé "^_^ ', position: 'is-top-right'});
            }).catch(err => {
                Toast.open({
                    type: 'is-danger',
                    message: 'Bon là ça craint, il faut ouvrir la console \'-_- ',
                    position: 'is-top-right'
                });
                console.error(err);
            });
        }
    });
    webSocket.addEventListener('message', (event) => {
        let message;
        try {
            message = JSON.parse(event.data);
        } catch (e) {
            return console.error('Unable to parse message from WebSocket server :', event.data);
        }
        //console.log('addEventListener: message', message);

        if (message.request === 'securitaz-create-event' && message.success === true) {
            store.commit('securitaz/addEvent', {event: message.event});
        } else if (message.request === 'securitaz-create-registration'
            && message.eventId === store.state.securitaz.currentEventId && message.success === true) {
            store.commit('securitaz/addRegistration', {registration: message.registration});
        } else if (message.request === 'securitaz-delete-registration'
            && message.eventId === store.state.securitaz.currentEventId && message.success === true) {
            store.commit('securitaz/removeRegistration', {sciper: message.sciper, eventId: message.eventId});
        } else if (message.request === 'securitaz-batch-create-registrations' && message.success === true
            && message.eventId === store.state.securitaz.currentEventId) {
            let registrationsCopy = store.state.securitaz.registrations;
            for (let registration of message.registrations) {
                registrationsCopy[registration.sciper] = registration;
            }
            store.commit('securitaz/setRegistrations', {registrations: registrationsCopy});
        } else if (message.request === 'securitaz-create-update-access-log'
            && message.eventId === store.state.securitaz.currentEventId && message.success === true) {
            store.commit('securitaz/createOrUpdateAccessLog', {
                accessLog: message.accessLog,
                accessGranted: message.accessGranted
            });
        } else if (message.request === 'securitaz-delete-access-log' && message.success === true
            && message.eventId === store.state.securitaz.currentEventId) {
            store.commit('securitaz/removeAccessLog', {sciper: message.sciper, eventId: message.eventId});
        } else if (message.request === 'securitaz-new-reader') {
            Toast.open({type: 'is-info', message: 'Un lecteur CAMIPRO a été connecté', position: 'is-top-left'});
        } else if (message.request === 'securitaz-reader-disconnected') {
            Toast.open({type: 'is-warning', message: 'Un lecteur CAMIPRO a été déconnecté', position: 'is-top-left'});
        } else {
            // console.log('Global dispatch ignored this message : ', {message});
        }

        if (message.id) {
            if (wsHandlers[message.id]) {
                wsHandlers[message.id](message);
                delete wsHandlers[message.id];
            }
        } else {
            if (!message.broadcast && message.request !== 'securitaz-heartbeat')
                console.error('Unable to dispatch message from WebSocket server (missing message.id ?) :', event.data);
        }
    });
    webSocket.addEventListener('error', (event) => {
        console.error('WebSocket Error : ', event);
        store.state.securitaz.loggedIn = false;
        setTimeout(() => {
            if (!store.state.securitaz.loggedIn)
                showReconnectDialog();
        }, 1000);
    });
    webSocket.addEventListener('close', (event) => {
        console.error('WebSocket Closed : ', event);
        store.state.securitaz.loggedIn = false;
        setTimeout(() => {
            if (!store.state.securitaz.loggedIn)
                showReconnectDialog();
        }, 1000);
    });
}

connectWS();

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
