<template>
  <div class="container box">
    <div class="columns">
      <div class="column">
        <div class="is-flex">
          <b-button type="is-info" @click="$router.push({name:'securitaz-registration', params: $route.params})"
                    style="margin-right: 10px;">Gérer inscriptions
          </b-button>
          <b-autocomplete
              class="is-flex-grow-1"
              placeholder="Ajout manuel via nom, prénom ou SCIPER"
              keep-first
              :data="searchResults"
              @typing="searchPeople"
              expanded
              :custom-formatter="p => `${p.firstname} ${p.name.toUpperCase()} [${p.sciper}]`"
              @select="selected => display(selected)"
              clearable/>
        </div>
        <SzCurrentPerson v-if="displayResult" :current-person="currentPerson"></SzCurrentPerson>
      </div>
      <div class="column box col-box">
        <b-field>
          <b-taginput
              v-model="filters"
              :data="filteredTags"
              autocomplete
              :allow-new="false"
              :open-on-focus="true"
              icon="label"
              placeholder="Filtrage par appareil"
              @typing="getFilteredTags">
          </b-taginput>
        </b-field>
        <div class="access-logs-history">
          <SzAccessLogRow :accessLog="ac" v-for="ac in filteredAccessLogs" v-bind:key="ac.time"
                          @selected="showUser"></SzAccessLogRow>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import SzAccessLogRow from "./SzAccessLogRow";
import SzCurrentPerson from "./SzCurrentPerson";

export default {
  name: 'SecuritazMainView',
  components: {SzAccessLogRow, SzCurrentPerson},
  data: () => ({
    searchResults: [],
    currentPerson: {},
    displayResult: false,
    filters: [],
    filteredTags: []
  }),
  computed: {
    ...mapState({
      accessLogs: state => state.securitaz.accessLogs,
      devices: state => state.securitaz.devices,
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    filteredAccessLogs: function () {
      if (this.filters.length === 0) return this.accessLogs;
      return this.accessLogs.filter(accessLog => this.filters.includes(accessLog.device));
    }
  },
  methods: {
    searchPeople(value) {
      this.displayResult = false;
      this.$store.dispatch('securitaz/searchPeople', {search: value}).then(results => {
        this.searchResults = results;
      });
    },
    display(p) {
      if (!p) throw 'p is null';
      this.currentPerson = p;
      this.currentPerson.units = [...new Set(this.currentPerson.units)];

      this.displayResult = true;
      // this.$forceUpdate(); // no longer necessary thanks to the clean up
    },
    showUser(sciper) {
      this.$store.dispatch('securitaz/searchPeople', {search: sciper}).then(users => this.display(users[0]));
    },
    getFilteredTags(text) {
      this.filteredTags = text.length === 0 ? this.devices : this.devices.filter((device) => {
        return device.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
      });
    }
  },
  mounted() {
    this.$store.dispatch('securitaz/getRegistrations', {eventId: this.$route.params.eventId});
    this.$store.dispatch('securitaz/getAccessLogs', {eventId: this.$route.params.eventId});
  }
}
</script>

<style scoped lang="scss">
@import '../../theme.scss';

.container {
  margin-top: 15px;
}

.columns {

  .col-box {
    margin-left: 20px;
    background-color: $grey-lighter !important;

    .access-logs-history {
      height: 80vh;
      overflow: auto;
    }
  }
}

</style>
