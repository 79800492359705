<template>
  <div class="box event-box">
    <div class="event-box-row">
      <b-button type="is-info" outlined class="event-show-btn" @click="$router.push({name: 'securitaz-registration', params: {eventId: eventData.id}})">Gérer inscriptions</b-button>
      <b-button type="is-success" class="event-show-btn" @click="$router.push({name: 'securitaz-mainView', params: {eventId: eventData.id}})">Afficher</b-button>
      <b-button type="is-black" outlined class="event-show-btn" @click="$router.push({name: 'securitaz-stats', params: {eventId: eventData.id}})">Stats</b-button>
      <h1 class="event-name">{{eventData.name}}</h1>
    </div>
    <div class="event-box-row">
      <h2 v-if="eventData.maxParticipants > 0" class="event-info">{{eventData.participants}}/{{eventData.maxParticipants}} participants</h2>
      <h2 v-else class="event-info">{{eventData.participants}} participants</h2>

      <b-taglist class="event-tags">
        <b-tag v-for="l in eventData.parsedRules.levels" v-bind:key="l" type="is-dark">{{l}}</b-tag>
        <b-tag v-for="s in eventData.parsedRules.sections" v-bind:key="s" type="is-info">{{s}}</b-tag>
      </b-taglist>

      <h2 class="event-info">Identifiant d'événement : {{eventData.id}}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SzEventRow',
  props: {
    eventData: {},
  },
}
</script>

<style scoped lang="scss">
@import '../../theme.scss';

.event-box {
  background-color: $grey-lighter !important;

  .event-box-row {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .event-name {
    font-size: x-large;
  }

  .event-show-btn, .event-info, .event-tags {
    margin-right: 20px;
  }
}

</style>
